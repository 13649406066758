<script lang="ts">
  import { goto } from "$app/navigation";
  import { onMount } from "svelte";
  onMount(() => {
    goto("/words/info");
  });
</script>

<section class="hero">
  <div class="hero-body">
    <p class="title">Hoi!</p>
    <p class="subtitle">Hier kun je woorden aanpassen.</p>
  </div>
</section>
